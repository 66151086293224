import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

export const useSocket = (getToken: () => Promise<string | null>) => {
  const [token, setToken] = useState<string | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    let isActive = true;

    const refreshToken = async () => {
      try {
        const freshToken = await getToken();
        if (isActive && token !== freshToken) {
          setToken(freshToken);
        }
      } catch (err) {
        console.error("Error refreshing token:", err);
      }
    };

    refreshToken();

    const interval = setInterval(refreshToken, 5000);

    return () => {
      isActive = false;
      clearInterval(interval);
    };
  }, [getToken, token]);

  useEffect(() => {
    if (token) {
      const freshSocket = io(API_BASE_URL, {
        transports: ["websocket"], // Force WebSocket-only transport
        auth: {
          token: `Bearer ${token}`,
        },
      });

      freshSocket.on("connect_error", (err: Error) => {
        console.error("Socket connection error:", err.message);
      });

      setSocket(freshSocket)

      return () => {
        freshSocket.disconnect()
      }
    }
  }, [token]);

  return socket;
};
