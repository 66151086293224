import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Card,
  CardContent,
  CardFooter,
} from "@repo/ui/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";
import { Switch } from "@repo/ui/components/ui/switch";
import { Textarea } from "@repo/ui/components/ui/textarea";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from "react-router-dom";
import { Spinner } from "../organisms/";
import { components } from '../services/api/openapi';

import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import invariant from 'tiny-invariant';
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@repo/ui/components/ui/button";
import { Input } from "@repo/ui/components/ui/input";
import React, { useEffect, } from "react";
import { useForm } from "react-hook-form";
import { fetcher, serializeError } from '../services/api';

const formSchema = z.object({
  name: z.string().min(2).max(50),
  website: z.string().url(),
  description: z.string(),
  tone: z.string(),
  targetAudience: z.string(),
  isActive: z.boolean(),
})

const apiSchema = z.object({
  name: z.string(),
  website: z.string(),
  description: z.string(),
  tone: z.string(),
  target_audience: z.string(),
  is_active: z.boolean(),
});

type FormType = z.infer<typeof formSchema>;
type APIType = z.infer<typeof apiSchema>;

const apiToFormSchema = apiSchema.transform((data) => {
  const transformedData = { ...data } as Partial<APIType>
  delete transformedData.target_audience
  delete transformedData.is_active
  return {
    ...transformedData,
    targetAudience: data.target_audience,
    isActive: data.is_active
  }
})

const formToApiSchema = formSchema.transform((data) => {
  const transformedData = { ...data } as Partial<FormType>
  delete transformedData.targetAudience
  delete transformedData.isActive
  return {
    ...transformedData,
    target_audience: data.targetAudience,
    is_active: data.isActive
  }
})


interface WorkspacesEditProps {}

const WorkspacesEdit: React.FC<WorkspacesEditProps> = () => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const { workspaceId } = useParams();
  invariant(workspaceId, "Unexpected workspaceId")

  const workspacessUpdate = useMutation({
    mutationFn: async (workspacePayload: components["schemas"]["UpdateWorkspaceRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/workspaces/{workspace_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            workspace_id: parseInt(workspaceId)
          }
        },
        body: workspacePayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["get", "workspaces", workspaceId] });

      toast.success("Workspace updated!",
        { description: data.data.name })
      return navigate("/workspaces");
    },
    onError: (error) => {
      toast.error("Cannot update workspace",
        { description: `${error}` })
    },
  })

  const { data, error, status } = useQuery({
    queryKey: ["get", "workspaces", workspaceId],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/workspaces/{workspace_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            workspace_id: parseInt(workspaceId)
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {}
  })

  useEffect(() => {
    if (status === "success" && data) {
      const parsedData = apiToFormSchema.parse({
        name: data.data.name,
        website: data.data.website,
        description: data.data.description,
        tone: data.data.tone,
        target_audience: data.data.target_audience,
        is_active: data.data.is_active,
      });

      form.reset(parsedData);
    }
  }, [status, data, form]);

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  const onSubmit = (values: FormType) => {
    workspacessUpdate.mutate(formToApiSchema.parse(values))
  }

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Workspace</h1>
      </div>
      <div className="flex flex-col w-full h-full gap-4">

        <Card>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-8"
            >

              <CardContent className="p-6 space-y-8">
                <div className="space-y-8">
                  <div className="space-y-2">
                    <h2 className="text-2xl font-semibold leading-none tracking-tight">Metadata</h2>
                  </div>
                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex gap-2 items-center">
                            <FormLabel>Name</FormLabel>
                          </div>

                          <FormControl>
                            <Input placeholder="My Awesome Product" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="website"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Website</FormLabel>
                          <FormControl>
                            <Input placeholder="https://myawesomewebsite.com" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex gap-2 items-center">
                            <FormLabel>Description</FormLabel>
                          </div>

                          <FormControl>
                            <Textarea
                              placeholder="Tell us a little bit about your project"
                              className="resize-none h-36"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                <div className="space-y-8">
                  <div className="space-y-2">
                    <h2 className="text-2xl font-semibold leading-none tracking-tight">Content Profile</h2>
                  </div>
                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="tone"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex gap-2 items-center">
                            <FormLabel>Tone</FormLabel>
                          </div>

                          <FormControl>
                            <Input placeholder="Professional" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="targetAudience"
                      render={({ field }) => (
                        <FormItem>
                          <div className="flex gap-2 items-center">
                            <FormLabel>Target audience</FormLabel>
                          </div>

                          <FormControl>
                            <Input placeholder="Founders" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>

                <FormField
                  control={form.control}
                  name="isActive"
                  render={({ field }) => (
                    <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                      <div className="flex gap-2 items-center">
                        <FormLabel className="text-base flex gap-2 justify-center items-center">
                          <span>Active</span>
                        </FormLabel>
                      </div>

                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </CardContent>

              <CardFooter className="flex justify-end">
                <Button type="submit" aria-label="start">
                  Save
                </Button>
              </CardFooter>

            </form>
          </Form>
        </Card>

      </div>
    </div>
  )
}

WorkspacesEdit.displayName = "WorkspacesEdit";

export { WorkspacesEdit };
