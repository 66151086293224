import { SignUp as ClerkSignUp } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SignUp = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitationKey = params.get('invitation-key');

    console.log(invitationKey);

    if (invitationKey) {
      localStorage.setItem('invitationKey', invitationKey);
    }
  }, [location]);

  return (
    <ClerkSignUp routing="path" path="/auth/sign-up" signInUrl="/auth/sign-in" />
  )
}

export { SignUp };
