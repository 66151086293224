import { cva, type VariantProps } from "class-variance-authority"
import * as React from "react"

import { cn } from "#/lib/utils"

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        success:
          "border-transparent bg-success text-success-foreground hover:bg-success",
        warning:
          "border-transparent bg-warning text-warning-foreground hover:bg-warning",
        error:
          "border-transparent bg-error text-error-foreground hover:bg-error",
        info:
          "border-transparent bg-info text-info-foreground hover:bg-info",
        outline: "text-foreground",
        "score-red":
          "border-transparent bg-score-red text-score-red-foreground hover:bg-score-red",
        "score-orange":
          "border-transparent bg-score-orange text-score-orange-foreground hover:bg-score-orange",
        "score-yellow":
          "border-transparent bg-score-yellow text-score-yellow-foreground hover:bg-score-yellow",
        "score-yellowgreen":
          "border-transparent bg-score-yellowgreen text-score-yellowgreen-foreground hover:bg-score-yellowgreen",
        "score-lightgreen":
          "border-transparent bg-score-lightgreen text-score-lightgreen-foreground hover:bg-score-lightgreen",
        "score-green":
          "border-transparent bg-score-green text-score-green-foreground hover:bg-score-green",
      },
      shape: {
        default: "",
        circle: "rounded-full h-10 w-10 p-4 justify-center"
      }
    },
    defaultVariants: {
      variant: "default",
      shape: "default"
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
  VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, shape, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant, shape }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
